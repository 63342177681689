import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { cardsServices, sliderCompaniesServices, testimonials } from "../utils/servicesInformation";

import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";




// markup
const Services = () => {


    return (
        <Layout
            SEOTitle={"Services - United Legal Assistance"}
            SEODescription={"Our Sevices include Uninsured Loss Recovery, Credit Hire & Credit Repair, Third-Party Intervention. Trusted by hundreds."}
            footerDisclaimer={""}>

            <Comparison props={cardsServices} />

            <ImageCarousel props={sliderCompaniesServices} />

            <ImageCarousel props={testimonials} />

        </Layout>
    )

}

export default Services
//companies slider
import image1 from '../assets/1-dcj.webp'
import image2 from '../assets/2-wrightsure.webp'
import image3 from '../assets/3-jbs.webp'
import image4 from '../assets/4-fnc.webp'
import image5 from '../assets/5-russell.webp'
import image6 from '../assets/6-whittlesey.webp'
import image7 from '../assets/7-htc.webp'
import image8 from '../assets/8-specialist.webp'
import image9 from '../assets/9-sib.webp'
import image10 from '../assets/10-trdirect.webp'
import image11 from '../assets/11-jabrokers.webp'
import image12 from '../assets/12-bislogo.webp'
import image13 from '../assets/13-hinks.webp'
import image14 from '../assets/14-west-craven.webp'
import image15 from '../assets/15-wcib.webp'
import image16 from '../assets/16-business-compare.webp'
import services1 from '../assets/services-column1.webp'
import services2 from '../assets/services-column2.webp'
import services3 from '../assets/services-column3.webp'

export const cardsServices = {
    title: "Our Services",
    comparison: [
        {
            theme: "light",
            background: services1 ,
            bgColor: "#015D8C",
            subtitle: "UNINSURED LOSS RECOVERY",
            info: "Flexible claims management solutions tailored to individual requirements.",
            label: "LEARN MORE",
            url: "/uninsured-loss-recovery/"
        },
        {
            theme: "dark",
            background: services2,
            bgColor: "#127F91",
            subtitle: "CREDIT HIRE & CREDIT REPAIR",
            info: "Bespoke service to manage your company’s fleet, minimising unnecessary risk.",
            label: "LEARN MORE",
            url: "/credit-hire-and-credit-repair/"
        },
        {
            theme: "light",
            background: services3,
            bgColor: "#015D8C",
            subtitle: "THIRD-PARTY INTERVENTION",
            info: "24-hour claims reporting line, where we support clients through a stressful process.",
            label: "LEARN MORE",
            url: "/third-party-intervention/"
        },

    ]
}

export const sliderCompaniesServices = {
    theme: 'light',
    title: 'TRUSTED BY HUNDREDS',
    description: '',
    images: [
        {
            image: image1,
        },
        {
            image: image2,
        },
        {
            image: image3,
        },
        {
            image: image4,
        },
        {
            image: image5,
        },
        {
            image: image6,
        },
        {
            image: image7,
        },
        {
            image: image8,
        },
        {
            image: image9,
        },
        {
            image: image10,
        },
        {
            image: image11,
        },
        {
            image: image12,
        },
        {
            image: image13,
        },
        {
            image: image14,
        },
        {
            image: image15,
        },
        {
            image: image16,
        },
    ]
}

export const testimonials = {
    theme: 'dark',
    title: 'Testimonial',
    description: '',
    bgColor: "#005d8f",
    text: [
        {
            subtitle: "James Brown – Managing Director",
            info: "“Having been an Insurance Broker for over 30 years, I can honestly say that the service & professionalism provided by United Legal Assistance is second to none. I would strongly recommend them to anyone.”",
        },
        {
            subtitle: "Chris Breeze – CEO",
            info: "“After experiencing numerous issues with previous providers of motor legal expenses policies, we decided to change to United Legal Assistance in September 2014. During this time, the whole team has provided an exceptional service, as well as an innovative approach to our clients and companies needs. ULA are polite, professional and efficient in their dealing.”",
        },
        {
            subtitle: "Cath Allsobrook – Operations Director",
            info: "“To improve our claims service and provide our clients with a 24 hour backed claims line we decided to test United Legal Assistance. The results were emphatic, with our clients making the effort to tell us of the excellent service they had received in the unfortunate event of a claim. We have since signed up with ULA and our account manager Daniel, has ensured that the transition has been as smooth as possible. The whole team has provided an exceptional service, as well as an innovative approach to our clients and company’s needs. ULA are polite, professional and efficient in their dealing.”",
        },
        {
            subtitle: "Don King – Director Wrightsure Services Ltd",
            info: "“I can confirm that we have used the above Company for the last 12 months, who have been able to produce a Legal Expenses facility for our Clients, who have not had the benefit of this cover and have found ULA to be thoroughly professional in our dealings with them.”",
        },
    ]
}